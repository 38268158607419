import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { SupportedLanguages } from "@/enums/internationalization";

i18n
  .use(HttpApi)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ["routes", "login", "common"],
    lng: "en",
    fallbackLng: "en",
    supportedLngs: Object.values(SupportedLanguages),

    // Enables useful output in the browser’s
    // dev console.
    debug: false,

    // Normally, we want `escapeValue: true` as it
    // ensures that i18next escapes any code in
    // translation messages, safeguarding against
    // XSS (cross-site scripting) attacks. However,
    // React does this escaping itself, so we turn
    // it off in i18next.
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
