"use client";

import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import { SubscriptionStatus } from "@/enums/stripe";
import { AddGrowSurfScript } from "@/utils/growSurf";
import Script from "next/script";
import { useEffect } from "react";

declare global {
  interface Window {
    zE: any;
  }
}

let hasAddedGrowSurf = false;

const ZenDesk = () => {
  const { user, isAuthenticated, subscription, isFreeTrial } = useAuthContext();

  useEffect(() => {
    if (
      !isAuthenticated ||
      !user ||
      hasAddedGrowSurf ||
      isFreeTrial ||
      !subscription ||
      subscription.status !== SubscriptionStatus.active
    ) {
      return;
    }

    hasAddedGrowSurf = true;
    AddGrowSurfScript();
  }, [user, isAuthenticated, subscription, isFreeTrial]);

  if (!isAuthenticated || !user) {
    return null;
  }

  return (
    <>
      <Script
        id="ze-snippet"
        src="https://js.hs-scripts.com/45566936.js"
        defer
      />
    </>
  );
};

export default ZenDesk;
