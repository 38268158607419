import { GrowSurfCampaigns } from "@/enums/growSurf";
import {
  GrowSurfParticipant,
  GrowSurfParticipantResponse,
  GrowSurfParticipantUpdate,
} from "@/types/growSurf";
import axios from "axios";

export const GrowSurfBaseUrl = "https://api.growsurf.com/v2";

export const AddGrowSurfParticipant = async (
  participant: GrowSurfParticipant
) => {
  const headers = {
    Authorization: `Bearer ${process.env.GROWSURF_API_KEY}`,
  };

  const growSurfResponse = await axios<
    GrowSurfParticipant,
    GrowSurfParticipantResponse
  >({
    url: `${GrowSurfBaseUrl}/campaign/${GrowSurfCampaigns.GAIL}/participant`,
    method: "POST",
    data: participant,
    headers,
  });

  return growSurfResponse;
};

export const UpdateGrowSurfParticipantByEmail = async (
  email: string,
  participant: GrowSurfParticipantUpdate
) => {
  const headers = {
    Authorization: `Bearer ${process.env.GROWSURF_API_KEY}`,
  };

  const encodedEmail = encodeURIComponent(email);

  const growSurfResponse = await axios<
    GrowSurfParticipant,
    { data: GrowSurfParticipantResponse }
  >({
    url: `${GrowSurfBaseUrl}/campaign/${GrowSurfCampaigns.GAIL}/participant/${encodedEmail}`,
    method: "POST",
    data: participant,
    headers,
  });

  return growSurfResponse;
};

export const TriggerGrowSurfReferral = async (email: string) => {
  const headers = {
    Authorization: `Bearer ${process.env.GROWSURF_API_KEY}`,
  };

  const growSurfResponse = await axios<undefined, any>({
    url: `${GrowSurfBaseUrl}/campaign/${GrowSurfCampaigns.GAIL}/participant/${email}/ref`,
    method: "POST",
    headers,
  });

  return growSurfResponse;
};

export const AddGrowSurfScript = () => {
  const script = document.createElement("script");
  script.src = "https://app.growsurf.com/growsurf.js?v=2.0.0";
  script.setAttribute("grsf-campaign", GrowSurfCampaigns.GAIL);
  script.async = true;
  document.head.appendChild(script);
};
