"use client";

import { GoogleAnalytics } from "nextjs-google-analytics";
import { PUBLIC_ENV_VARS } from "@/utils/common/environment";

export default function Analytics() {
  return (
    <>
      {PUBLIC_ENV_VARS.GOOGLE_ANALYTICS_MEASUREMENT_ID && (
        <GoogleAnalytics
          strategy="lazyOnload"
          gaMeasurementId={PUBLIC_ENV_VARS.GOOGLE_ANALYTICS_MEASUREMENT_ID}
          trackPageViews
        />
      )}
    </>
  );
}
