import Script from "next/script";
import React from "react";

const ReleaseNotesInitialization = () => {
  return (
    <Script
      id="release-notes-widget"
      strategy="afterInteractive" // Use 'afterInteractive' to load the script after the page is interactive
      dangerouslySetInnerHTML={{
        __html: `!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");`,
      }}
    />
  );
};

export default ReleaseNotesInitialization;
